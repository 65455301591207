import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import './Home.css';
import logoTexto from '../../Images/logo-texto.png'
import Button from '../../Components/Button/Button'
import vetor from '../../Images/vetor.png'
import vetor2 from '../../Images/vetor2.png'
import computer from '../../Images/computer.png'
import computer2 from '../../Images/computer2.png'
import caminhoes from '../../Images/caminhoes.png'
import img1 from '../../Images/img1.png'
import img2 from '../../Images/img2.png'
import img3 from '../../Images/img3.png'
import img4 from '../../Images/img4.png'
import img5 from '../../Images/img5.png'
import img6 from '../../Images/img6.png'
import img7 from '../../Images/img7.png'
import img8 from '../../Images/img8.png'
import img9 from '../../Images/img9.png'
import img10 from '../../Images/img10.png'
import img11 from '../../Images/img11.png'
import img12 from '../../Images/img12.png'
import img1_2 from '../../Images/img1-2.png'
import img2_2 from '../../Images/img2-2.png'
import img3_2 from '../../Images/img3-2.png'
import img4_2 from '../../Images/img4-2.png'
import img5_2 from '../../Images/img5-2.png'
import img6_2 from '../../Images/img6-2.png'
import img7_2 from '../../Images/img7-2.png'
import img8_2 from '../../Images/img8-2.png'
import img9_2 from '../../Images/img9-2.png'
import img10_2 from '../../Images/img10-2.png'
import img11_2 from '../../Images/img11-2.png'
import img12_2 from '../../Images/img12-2.png'
import logo3 from '../../Images/logo3.png'
import Footer from '../../Components/Footer/Footer';
import elipse from '../../Images/elipse.png'
import elipse2 from '../../Images/elipse2.png'
import fechar from '../../Images/btn-fechar.png'
import seta_e from '../../Images/seta-e.png'
import seta_d from '../../Images/seta-d.png'
import grafico from '../../Images/grafico.png'
import carregamento from '../../Images/carregamento.png'
import financeiro from '../../Images/financeiro.png'
import localizacao from '../../Images/localizacao.png'

function Home() {

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_aevxgn9', 'template_rjiicgg', e.target, 'q0IVq-NMqvaknOTvx')
            .then((result) => {
                console.log(result.text);
                setShowSuccessModal(true);
                setShowErrorModal(false);
            }, (error) => {
                console.log(error.text);
                setShowErrorModal(true);
                setShowSuccessModal(false);
            });
    }
    useEffect(() => {
        // Seleciona os elementos .vetor e .vetor2
        const vetor = document.querySelector('.vetor');
        const vetor2 = document.querySelector('.vetor2');

        // Define a função que será chamada quando o mouse passar sobre o elemento .vetor
        const handleMouseOver = () => {
            vetor2.style.transform = 'translate(2vw, 3vw)';
        };

        // Define a função que será chamada quando o mouse sair do elemento .vetor
        const handleMouseOut = () => {
            vetor2.style.transform = '';
        };

        // Adiciona os event listeners
        vetor.addEventListener('mouseover', handleMouseOver);
        vetor.addEventListener('mouseout', handleMouseOut);

        // Retorna uma função de limpeza que remove os event listeners quando o componente é desmontado
        return () => {
            vetor.removeEventListener('mouseover', handleMouseOver);
            vetor.removeEventListener('mouseout', handleMouseOut);
        };
    }, []); // Passa um array vazio como segundo argumento para o useEffect para garantir que ele seja executado apenas uma vez

    const [showComputer, setShowComputer] = useState(false);

    const [showImg1, setShowImg1] = useState(false);
    const [showImg2, setShowImg2] = useState(false);
    const [showImg3, setShowImg3] = useState(false);
    const [showImg4, setShowImg4] = useState(false);
    const [showImg5, setShowImg5] = useState(false);
    const [showImg6, setShowImg6] = useState(false);
    const [showImg7, setShowImg7] = useState(false);
    const [showImg8, setShowImg8] = useState(false);
    const [showImg9, setShowImg9] = useState(false);
    const [showImg10, setShowImg10] = useState(false);
    const [showImg11, setShowImg11] = useState(false);
    const [showImg12, setShowImg12] = useState(false);
    return (
        <>
            <body>
                <div className='home'>
                    <section className='sec-1'>
                        <div className='div-1-sec-1'>
                            <img src={logoTexto} alt='logo' className='logo-txt' />
                            <p className='subtitulo'>SOLUÇÃO PARA<br /> TRANSPORTADORAS</p>
                        </div>
                        <div className='div-2-sec-1'>
                            <p className='texto'>Gerenciamento e acompanhamento de entregas</p>
                            <a href="#form"><Button title='Quero saber mais' /></a>
                        </div>
                    </section>
                    <section className='sec-2'>
                        <div className='div-1-sec-2'>
                            <h2 className='title-sec'>
                                QUEM SOMOS
                            </h2>
                            <p className='texto-sec txt-just'>
                                Nós somos uma startup inovadora especializada no desenvolvimento de softwares de alta qualidade, sob demanda. Nosso principal produto é o 'Gerenciador de Transportes', uma solução eficaz para otimizar operações de transporte. No entanto, não nos limitamos a isso: estamos sempre em busca de novos desafios e oportunidades para expandir nosso portfólio.
                            </p>
                            <p className='texto-sec txt-just'>
                                Nossa missão é simplificar as tarefas manuais do cotidiano empresarial, promovendo a automação e eficiência operacional. Nosso foco está em identificar e implementar indicadores chave que impulsionem o crescimento e a evolução dos negócios de nossos clientes.
                            </p>
                            <p className='texto-sec txt-just'>
                                Com um olhar voltado para o futuro, aspiramos nos transformar em uma referência no mercado de software, tanto no cenário nacional quanto internacional. Nosso compromisso é com a inovação contínua e a excelência, visando sempre a evolução tecnológica e a satisfação dos nossos parceiros e clientes.
                            </p>
                        </div>
                        <div className='div-2-sec-2'>
                            <img src={vetor} alt='Imagem de caminhão' className='vetor' />
                            <img src={vetor2} alt='Imagem de caminhão' className='vetor2' />
                        </div>
                    </section>
                    <section className='sec-3'>
                        <div className='div-1-sec-3'>
                            {showComputer ?
                                <div className='imgs-computer1'>
                                    <img src={grafico} className='grafico' />
                                    <img src={seta_e} className='seta1' />
                                    <img src={carregamento} className='carregamento' />
                                    <img src={seta_e} className='seta2' />
                                </div>
                                :
                                <div></div>
                            }
                            <img src={showComputer ? elipse2 : elipse} className='elipse' />
                            <img src={showComputer ? computer2 : computer} className='img-computer' onMouseEnter={() => { setShowComputer(true) }}
                                onMouseLeave={() => { setShowComputer(false) }} />
                            {showComputer ?
                                <div className='imgs-computer2'>
                                    <img src={financeiro} className='financeiro' />
                                    <img src={seta_d} className='seta3' />
                                    <img src={localizacao} className='localizacao' />
                                    <img src={seta_d} className='seta4' />
                                </div>
                                :
                                <div></div>
                            }
                        </div>
                        <div className='div-2-sec-3'>
                            <h2 className='title-sec'>
                                Facilite os seus embarques
                            </h2>
                            <p className='texto-sec txt-dir'>
                                Cadastre  todos os seus embarques, tenha acesso as informações essenciais de cada entrega
                            </p>
                            <p className='texto-sec txt-esq'>
                                Saiba se os pedidos estão dentro do prazo, acompanhe suas entregas em tempo real: Integração com os rastreadores da Omnilink e Onixsat
                            </p>
                            <p className='texto-sec txt-dir'>
                                Acompanhe e entenda toda a logística de entregas da sua empresa, utilize de ferramentas que facilitam as tarefas do dia-a-dia
                            </p>
                        </div>
                    </section>
                    <section className='sec-4'>
                        <div className='div-1-sec-4'>
                            <h2 className='title-sec'>
                                Melhore suas tomadas de decisão
                            </h2>
                            <p className='texto-sec'>
                                Baseie suas decisões usando de indicadores fornecidos pela plataforma
                            </p>
                            <p className='texto-sec'>
                                O controle dinâmico permite a empresa ter uma projeção financeira futura calculado conforme o cadastro de embarques atuais
                            </p>
                            <p className='texto-sec'>
                                Acompanhe em tempo real o cenário das entregas, tenha melhores parâmetros sobee a evolução da empresa para verificar a eficiência dos embarques e desenvolver melhorias
                            </p>
                            <p className='texto-sec'>
                                Tenha acesso ao checklist de veículos cadastrados. Desenvolva estratégias de manutenção periódica
                            </p>
                        </div>
                        <div className='div-2-sec-4'>
                            <img src={caminhoes} className='img-caminhao' />
                            <div className='fundo-caminhao'></div>
                        </div>
                    </section>
                    <section className='sec-5'>
                        <div className='div-1-sec-5'>
                            <h2 className='title-5'>Principais funcionalidades</h2>
                            <p className='texto-5'><img src={showImg1 ? img1_2 : img1} className='img-5' onMouseEnter={() => { setShowImg1(true) }} onMouseLeave={() => { setShowImg1(false) }} />RESUMO DE EMBARQUE</p>
                            <p className='texto-5'><img src={showImg2 ? img2_2 : img2} className='img-5' onMouseEnter={() => { setShowImg2(true) }} onMouseLeave={() => { setShowImg2(false) }} />RESUMO DE PEDIDO</p>
                            <p className='texto-5'><img src={showImg3 ? img3_2 : img3} className='img-5' onMouseEnter={() => { setShowImg3(true) }} onMouseLeave={() => { setShowImg3(false) }} />RESUMO DO VEÍCULO</p>
                            <p className='texto-5'><img src={showImg4 ? img4_2 : img4} className='img-5' onMouseEnter={() => { setShowImg4(true) }} onMouseLeave={() => { setShowImg4(false) }} />RESUMO DE MERCADORIA</p>
                            <p className='texto-5'><img src={showImg5 ? img5_2 : img5} className='img-5' onMouseEnter={() => { setShowImg5(true) }} onMouseLeave={() => { setShowImg5(false) }} />RELATÓRIOS FINANCEIROS</p>
                            <p className='texto-5'><img src={showImg6 ? img6_2 : img6} className='img-5' onMouseEnter={() => { setShowImg6(true) }} onMouseLeave={() => { setShowImg6(false) }} />INDICADOR DE QUALIDADE E PONTUALIDADE NAS CARGAS</p>
                        </div>
                        <div className='div-2-sec-5'>
                            <p className='texto-5'><img src={showImg7 ? img7_2 : img7} className='img-5' onMouseEnter={() => { setShowImg7(true) }} onMouseLeave={() => { setShowImg7(false) }} />CADASTRO DETALHADO DE EMBARQUE</p>
                            <p className='texto-5'><img src={showImg8 ? img8_2 : img8} className='img-5' onMouseEnter={() => { setShowImg8(true) }} onMouseLeave={() => { setShowImg8(false) }} />CONTROLE DE EMBARQUE/ ENTREGA EM TEMPO REAL</p>
                            <p className='texto-5'><img src={showImg9 ? img9_2 : img9} className='img-5' onMouseEnter={() => { setShowImg9(true) }} onMouseLeave={() => { setShowImg9(false) }} />INTEGRAÇÃO COM RASTREADORES</p>
                            <p className='texto-5'><img src={showImg10 ? img10_2 : img10} className='img-5' onMouseEnter={() => { setShowImg10(true) }} onMouseLeave={() => { setShowImg10(false) }} />APLICATIVO MOBILE PARA CONTROLE DE INFORMAÇÕES E DE RASTREIO</p>
                            <p className='texto-5'><img src={showImg11 ? img11_2 : img11} className='img-5' onMouseEnter={() => { setShowImg11(true) }} onMouseLeave={() => { setShowImg11(false) }} />CONTROLE DE MANUTENÇÃO DA FROTA</p>
                            <p className='texto-5'><img src={showImg12 ? img12_2 : img12} className='img-5' onMouseEnter={() => { setShowImg12(true) }} onMouseLeave={() => { setShowImg12(false) }} />CONTROLE DE ACESSO AS INFORMAÇÕES DENTRO DA PLATAFORMA</p>
                        </div>
                    </section>
                    <section className='sec-6' id='sec-6'>
                        <div className='div-1-sec-6'>
                            <img src={logo3} alt='logo3' className='logo-3' />
                        </div>
                        <div className='div-2-sec-6' id='div-form'>
                            <form className='form' id='form' onSubmit={sendEmail}>
                                <h2 className='form-title'>Quer saber mais?</h2>
                                <p className='texto-sec'>Deixe suas informações com a gente e nossa equipe de consultores entrará em contato</p>
                                <input type='text' placeholder='Nome para contato' className='input-sec' name='user_name' required />
                                <input type='text' placeholder='Nome da tranportadora' className='input-sec' name='user_trans' required />
                                <input type='text' placeholder='Telefone / Whatsapp' className='input-sec' name='user_tel' required />
                                <input type='text' placeholder='E-mail' className='input-sec' name='user_email' required />
                                <input type='submit' value='Enviar' className='btn-form' />

                            </form>
                        </div>
                    </section>
                    <Footer />
                </div>
                {showSuccessModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <h3>Sucesso!</h3>
                            <p>O formulário foi enviado com sucesso.</p>
                            <img src={fechar} onClick={() => setShowSuccessModal(false)} alt='fechar' className='img-fechar' />
                        </div>
                    </div>
                )}

                {showErrorModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <h3>Erro!</h3>
                            <p>Ocorreu um erro ao enviar o formulário.</p>
                            <img src={fechar} onClick={() => setShowSuccessModal(false)} alt='fechar' className='img-fechar' />
                        </div>
                    </div>
                )}
            </body>
        </>
    )
}
export default Home;